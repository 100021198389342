<template>
    <div>
        <div @mouseover="showImage(link)" @mouseout="hideImage(link)">
            <a target="_blank" class="" :href="`${link}`" download>Foto</a>
        </div>
        <img :src="`${link}`"
            :id="'image__hover--' + link" class="table_image--hover table-image--measure" />
    </div>
</template>

<script>
export default {
    props: {
        link: [String, Number],
    },
    created() {

    }, 
    methods: {
        showImage(i) {
            document.getElementById('image__hover--' + i).style.display = "block";
        },

        hideImage(i) {
            document.getElementById('image__hover--' + i).style.display = "none";
        },
    },
};
</script>