<template>
    <div class="m-3 p-2 wrapper  wrapper--default">
        <h3 class="text-center">Near miss meldingen</h3>
        <hr>
        <button class="m2 employee_download" @click="createExcel">Near miss uitdraai</button>
        <Loading v-if="loading" />
        <table v-else class="w-100 table--default table__border--200 sortable">
            <thead class="table__head--200">
                <tr>
                    <th class="table__head--text">#</th>
                    <th class="table__head--text">Melder</th>
                    <th class="table__head--text">Wanneer?</th>
                    <th class="table__head--text">Duur?</th>
                    <th class="table__head--text">Locatie</th>
                    <th class="table__head--text">Incident</th>
                    <th class="table__head--text">Foto's</th>
                    <th class="table__head--text">Situatiebeschrijving</th>
                    <th class="table__head--text">Oplossingen?</th>
                    <th class="table__head--text">Risico</th>
                    <th class="table__head--text">Reactie Veiligheidsteam</th>
                    <th class="table__head--text">Afronden</th>
                    <th class="table__head--text">Verwijderen</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, key) in near_misses" :key="key" class="table__row--color"
                    :style="{ 'background-color': item.finished == true ? '#81c784' : '' }">
                    <td class="table__cell--default">{{ item.id }}</td>
                    <td class="table__cell--default" v-if="item.reporter">
                        {{ item.reporter }}
                    </td>
                    <td class="table__cell--default" v-else>Anoniem</td>
                    <td class="table__cell--default">
                        {{ item.whenobserved }}
                    </td>
                    <td class="table__cell--default">
                        {{ item.durationobserved }}
                    </td>
                    <td class="table__cell--default">
                        {{ item.location }}
                    </td>
                    <td class="table__cell--default">
                        {{ item.incident }}
                    </td>
                    <td class="table__cell--default">
                        <NormalImageHover v-for="(link, key) in item.images" :link="link" :key="key" />
                    </td>
                    <td class="table__cell--default">
                        {{ item.situationdescription }}
                    </td>
                    <td class="table__cell--default">
                        {{ item.solutionideas }}
                    </td>
                    <td class="table__cell--default bg-yellow" v-if="item.severity === 'low'">Laag</td>
                    <td class="table__cell--default bg-orange" v-else-if="item.severity === 'medium'">Middel</td>
                    <td class="table__cell--default bg-red" v-else-if="item.severity === 'high'">Hoog</td>
                    <td class="table__cell--default" v-else>Niet ingevuld...</td>
                    <td class="table__cell--default">
                        <span>{{ item.response }}</span>
                        <button class="daysVlist__postnote--button" @click="getIdFromRow(item.id)"> + </button>
                    </td>
                    <td class="table__cell--default">
                        <button class="employee_add--button" @click="finishNearMiss(item.id)">Afronden</button>
                    </td>
                    <td class="table__cell--default">
                        <button class="employee_delete" @click="deleteNearMiss(item.id)">Verwijderen</button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div>
            <b-modal id="create" ref="note_modal">
                <template #modal-header>
                    <h2>Voeg notitie toe</h2>
                </template>
                <textarea class="form-control" id="note_field" rows="3" v-model.lazy="response_model"></textarea>
                <fieldset id="severity_radio"
                    style="background-color: lightgray; border-radius: 5px; margin-top: 5px; padding: 3px">
                    <h3>Risico classificering</h3>
                    <input type="radio" id="low" value="low" name="severity">
                    <label for="low" class="mr-2 ml-1 yellow ">Laag</label>
                    <input type="radio" id="medium" value="medium" name="severity">
                    <label for="medium" class="mr-2 ml-1 orange">Middel</label>
                    <input type="radio" id="high" value="high" name="severity">
                    <label for="high" class="mr-2 ml-1 red">Hoog</label>
                </fieldset>
                <template #modal-footer>
                    <div class="w-100">
                        <b-button variant="primary" size="sm" class="float-right" @click="postNote">
                            Toevoegen
                        </b-button>
                    </div>
                </template>
            </b-modal>
        </div>
    </div>
</template>

<script>
import { msalInstance } from "vue-msal-browser";
import request from '@/functions/request.js';
import hasIndex from '@/functions/hasIndex/hasIndex.js';
import NormalImageHover from "@/components/NormalImageHover.vue";
import * as XLSX from "xlsx";
import Loading from '@/components/Loading.vue';
import 'sortable-tablesort/sortable.min.css'
import 'sortable-tablesort/sortable.min.js'

export default {
    components: { NormalImageHover, Loading },
    data: () => ({
        response_model: "",
        add_note_id: null,
        near_misses: null,
        OG_misses: null,
        severity: null,
        loading: true,
    }),
    created() {
        this.getData();
    },


    methods: {
        getData() {
            request('near-miss/get-all', "GET").then(({ near_misses }) => {
                this.OG_misses = near_misses;
                this.near_misses = near_misses;
                this.loading = false;
            })
        },

        sortColumn(columnKey, ascending = true) {
            this.near_misses = this.OG_misses.sort(
                (a, b) => {
                    const valueA = a[columnKey];
                    const valueB = b[columnKey];

                    // Handle different data types (strings, numbers)
                    if (typeof valueA === 'string' && typeof valueB === 'string') {
                        return valueA.localeCompare(valueB, undefined, { numeric: true }) * (ascending ? 1 : -1);
                    } else {
                        return (valueA - valueB) * (ascending ? 1 : -1);
                    }
                });
        },

        getIdFromRow(id) {
            this.$refs["note_modal"].show();
            console.log(id)
            this.add_note_id = id;
        },

        postNote() {
            const severity = document.querySelector('input[type="radio"][name="severity"]:checked').value;
            const posturl = "near-miss/post-response";
            const data = {
                id: this.add_note_id,
                responder: msalInstance.getAllAccounts()[0].idTokenClaims.name,
                datum: this.moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
                response: this.response_model,
                severity: severity
            };
            request(posturl, "PATCH", data).then((values) => {
                let toastProps = {};
                if (hasIndex(values.message, "response geupdate!")) {
                    toastProps = {
                        message: values.message,
                        type: "success",
                    };
                    this.$refs["note_modal"].hide();
                    location.reload();
                } else {
                    toastProps = {
                        message: values.error,
                        type: "danger",
                    };
                    this.$refs["note_modal"].hide();
                }

                this.$store.dispatch("addNotification", toastProps);
            });
        },

        finishNearMiss(id) {
            request(`near-miss/finish/${id}`, "PATCH").then((values) => {
                let toastProps = {};
                if (hasIndex(values.message, "Afgerond")) {
                    toastProps = {
                        message: values.message,
                        type: "success",
                    };
                } else {
                    toastProps = {
                        message: "Er ging iets fout",
                        type: "danger",
                    };
                }
                this.$store.dispatch("addNotification", toastProps);
                location.reload();
            })
        },

        deleteNearMiss(id) {
            if (confirm(`Weet je zeker dat je near miss ${id} wilt verwijderen?`)) {
                request(`near-miss/delete/${id}`, "DELETE").then((values) => {
                    let toastProps = {};
                    if (hasIndex(values.message, "Verwijderd")) {
                        toastProps = {
                            message: values.message,
                            type: "success",
                        };
                    } else {
                        toastProps = {
                            message: "Er ging iets fout",
                            type: "danger",
                        };
                    }
                    this.$store.dispatch("addNotification", toastProps);
                    location.reload();
                })
            }
        },

        createExcel() {
            this.nearmisses = [];

            this.near_misses.forEach((i) => {
                this.nearmisses.push({
                    "id": i.id,
                    "Melder": i.reporter,
                    "Email": i.email,
                    "Wanneer?": i.whenobserved,
                    "Hoelang?": i.durationobserved,
                    "locatie": i.location,
                    "Situatie?": i.situationdescription,
                    "Oplossing?": i.solutionideas,
                    "Antwoord": i.response,
                    "Risico": i.severity,
                    "Afgerond": i.finished
                });
            });

            var telWS = XLSX.utils.json_to_sheet(this.nearmisses);
            var wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
                wb,
                telWS,
                `near_miss_${this.moment().format("DD-MM-YYYY")}`
            );
            XLSX.writeFile(
                wb,
                `near_miss_${this.moment().format("DD-MM-YYYY")}.xlsx`
            );
        }
    }
}
</script>